<template>
  <v-container class="pa-0 mt-1 d-flex align-start" :style="{ maxWidth: maxWidth + 'px' }">
    <v-card tile class="rounded-lg" width="100%"
      :height="$vuetify.breakpoint.height - 100 + 'px'" :loading="loading">
      <v-card-title class="primary white--text pa-1">
        <v-btn icon dark to="/">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="mr-2">Profile</span>
        <v-btn icon dark @click="logout()">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0 rounded-lg card-relative">
        <v-row>
          <v-col cols="12" md="2" sm="12">
            <v-card
                class="float-right text-center"
                flat
            >
              <div>
                <v-row dense>
                  <v-col cols="12">
                    <v-avatar
                        size="150"
                        class="mt-4"
                    >
                        <v-img
                            :src="display_pic"
                            @click="openFiles"
                            style="cursor: pointer;"
                            :lazy-src="FRONT_ASSETS + 'placeholder.png'"
                        ></v-img>
                    </v-avatar>
                  </v-col>
                  <span v-if="form_error.profile_pic" class="error--text text-xs">{{form_error.profile_pic}}</span>
                </v-row>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <v-card
                class="float-right text-center"
                flat
            >
                <v-card-text>
                  <v-row dense>
                    <input type="file" style="display:none;" ref="profileImage" accept="image/png, image/jpeg, image/jpg" @change="changeImage">

                    <v-col cols="12" md="6" sm="12">
                      <v-text-field :label="$t('profile.first_name')" v-model="formdata.first_name"
                      :error-messages="form_error.first_name"
                      :hide-details="!form_error.first_name"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field :label="$t('profile.last_name')" v-model="formdata.last_name"
                      :error-messages="form_error.last_name"
                      :hide-details="!form_error.last_name"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field :label="$t('profile.email')" v-model="formdata.email"
                      :error-messages="form_error.email"
                      disabled
                      :hide-details="!form_error.email"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field :label="$t('profile.phone_no')" v-model="formdata.phone_number"
                      :error-messages="form_error.phone_number"
                      :hide-details="!form_error.phone_number"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-text-field :label="$t('profile.compnay_name')" v-model="formdata.company_name"
                      :error-messages="form_error.company_name"
                      :hide-details="!form_error.company_name"></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" md="6" sm="12">
                      <v-select
                        :items="organization_type"
                        item-text="name"
                        item-value="category_id"
                        :label="$t('profile.category')"
                        :error-messages="form_error.organization_type"
                        :hide-details="!form_error.organization_type"
                        v-model="formdata.organization_type"
                        multiple
                      >
                      </v-select>
                    </v-col> -->
                    <v-col cols="12" md="6" sm="12">
                      <v-select
                        :items="online_status"
                        item-text="name"
                        item-value="id"
                        :label="$t('profile.online_status')"
                        :error-messages="form_error.online_status"
                        :hide-details="!form_error.online_status"
                        v-model="formdata.online_status"
                      >
                      </v-select>
                    </v-col>

                    <!-- <v-col cols="12" md="6" sm="12">
                      <v-text-field label="LinkedIn URL" v-model="formdata.linkedin_url"
                      :error-messages="form_error.linkedin_url"
                      :hide-details="!form_error.linkedin_url"></v-text-field>
                    </v-col> -->
                    <v-col cols="12" md="6" sm="12">
                      <v-textarea :label="$t('profile.designation')" v-model="formdata.designation"
                      :error-messages="form_error.designation"
                      rows="1"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-select label="Preferred language"
                      v-model="formdata.langauge_knows"
                      multiple :items="langauge_knows">
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="12" sm="12" class="text-right">
                      <v-btn tile color="primary" @click="updateProfile" dark>
                        {{$t('profile.update_btn')}}
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-card
                class="float-left text-center"
                max-width="350"
                flat
                :disabled="formdata.sponsor_type == 1"
            >
              <v-card-text>
                <v-row dense>
                  <v-col cols="12">
                    <v-text-field type="password" :label="$t('profile.current_pass')" v-model="formdata2.current_password"
                    :error-messages="form_error2.current_password"
                    :hide-details="!form_error2.current_password"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field type="password" :label="$t('profile.new_pass')" v-model="formdata2.new_password"
                    :error-messages="form_error2.new_password"
                    :hide-details="!form_error2.new_password"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field type="password" :label="$t('profile.confirm_new_pass')" v-model="formdata2.confirm_password"
                    :error-messages="form_error2.confirm_password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn block tile color="primary" @click="changePassword" dark>
                      {{$t('profile.change_pass_btn')}}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapMutations } from "vuex";
export default {
  name: "ProfileView",
  components : {
  },
  data() {
    return {
      loading: false,
      formdata : {
        organization_type: []
      },
      formdata2: {},
      display_pic: null,
      form_error : {},
      form_error2 : {},
      organization_type: [],
      online_status: [{
        id: 1,
        name: 'Available'
      },{
        id: 2,
        name: 'Busy'
      },{
        id: 3,
        name: 'Idle'
      },{
        id: 4,
        name: 'Offline'
      }],
      langauge_knows : [
        {
          value : 'ENG',
          text : 'English'
        },
        {
          value : 'ESP',
          text : 'Spanish'
        },
        {
          value : 'VIET',
          text : 'Vietnamese'
        }
      ]
    };
  },
  created: function () {
    let _self = this;
    axios.get("/organization/list")
    .then(function (response) {
      axios.get("/profile/details")
      .then(function (response2) {
          _self.organization_type = response.data.data
          let profileData = response2.data.data
          _self.formdata.first_name = profileData.first_name
          _self.formdata.last_name = profileData.last_name
          _self.formdata.email = profileData.email
          _self.display_pic = profileData.profile_pic
          _self.formdata.company_name = profileData.company_name
          _self.formdata.online_status = profileData.online_status
          _self.formdata.designation = profileData.designation
          _self.formdata.phone_number = profileData.phone_number
          _self.formdata.linkedin_url = profileData.linkedin_url
          _self.formdata.langauge_knows = profileData.langauge_knows
          _self.formdata.sponsor_type = profileData.sponsor_type
          for (let key in profileData.categories) {
            _self.formdata.organization_type.push(profileData.categories[key].category_id)
          }
      })
      .catch(function (e) {
        console.log(e);
      });
    })
    .catch(function (e) {
      console.log(e);
    });
  },
  computed: {
    ...mapState("utils", ["maxWidth"]),
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    changeImage(event) {
      const files = event.target.files
      this.formdata.profile_pic = files[0]
      this.display_pic = URL.createObjectURL(this.formdata.profile_pic)
    },
    openFiles() {
      this.$refs.profileImage.click()
    },
    updateProfile() {
      let _self = this;
      this.loading = "green";
       _self.form_error = {}
      let serverData = JSON.parse(JSON.stringify(this._data.formdata));
      let formData = new FormData();
      for (let key in serverData) {
        if(key != 'organization_type') {
          formData.append(key, serverData[key]);
        } else {
          for (let key2 in serverData[key]) {
            formData.append(`organization_type[${key2}]`, serverData[key][key2]);
          }
        }
      }
      if (this.formdata.profile_pic) {
        formData.append("profile_pic", this.formdata.profile_pic);
      }
      axios.post(`/update_profile`, formData)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
            let newData = response.data.user_data
            let existingData = JSON.parse(localStorage.getItem('churchil_user'))
            for(let key in newData) {
              if(existingData[key] && newData[key]){
                existingData[key] = newData[key]
              }
            }
            localStorage.setItem('churchil_user',JSON.stringify(existingData))
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error[errors[key].field] = errors[key].message
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    changePassword() {
      let _self = this;
      this.loading = "primary";
      _self.form_error2 = {}
      axios.post(`/change_password`, _self.formdata2)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.formdata2 = {}
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          }
          else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.form_error2[errors[key].field] = errors[key].message
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    logout(){
      localStorage.removeItem("churchil_user");
      this.$router.push("/login");
    }
  },
  mounted() {
    //this.getProfileData()
  },
};
</script>
<style>
</style>